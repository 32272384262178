.doseBoxExtra {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    height: 50%;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    border-radius: 15px;
    box-shadow: 1px 2px 8px rgba(88, 88, 88, 0.1);
    border-style: solid;
    border-width: 1px;
    border-color: rgba(128, 128, 128, 0.37);
  }

  .doseBox {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    height: 70%;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    border-radius: 15px;
    box-shadow: 1px 2px 8px rgba(88, 88, 88, 0.1);
    border-style: solid;
    border-width: 1px;
    border-color: rgba(128, 128, 128, 0.37);
  }
  
  .doseBoxContainer {
    position: relative;
    border-radius: 1px;
    margin-right: 7.5px;
    width: 48%;
    margin-bottom: 48px;
  }

  .doseBoxContainerExtra {
    position: relative;
    border-radius: 1px;
    margin-right: 7.5px;
    width: 48%;
    margin-top: 4px;
  }
  
  .content {
    width: 100%;
    display: flex;
    flex-flow: column;
    min-width: 0;
  }
  
  .title {
    color: rgb(0, 83, 140);
    font-size: 18px;
    line-height: 21px;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .textExtra {
    color: #000;
    font-size: 11px;
    line-height: 14px;
    overflow: hidden;
    overflow-wrap: break-word;
  }

  .text {
    color: #000;
    font-size: 15px;
    line-height: 18px;
    overflow: hidden;
    overflow-wrap: break-word;
  } 