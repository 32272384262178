.logo {
  max-height: 200px;
  width: 100%;
}

.background {
  background-color: #fbfbfd;
  padding-bottom: 80px;
  /* padding-top: 60px; */
  min-height: 100vh;
}

.container {
  /* border-radius: 8px; */
  /* background-color: white; */
  /* border: 1px #dfdfdf solid; */
  padding-bottom: 20px;
  /* box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15),
    0 5px 15px rgba(0, 0, 0, 0.1); */
}

.bisectedLayoutLowerContainer {
  padding-bottom: 20px;
  margin-top: 40px;
}

.imagePanel {
  border-radius: 2px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border: 1px #dfdfdf solid;
  padding: 15px;
  background: white;
  max-width: 700px;
  width: 95%;
  margin-top: -70px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.formWrapper {
  padding: 0px 15px;
}

:global .form-group.formio-component {
  margin-bottom: 0px;
}

:global .page-link {
  display: none;
}

.responsiveRight {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  justify-content: center;
  align-items: center;
}

.responsiveRightIconLabel {
  padding-right: 15px;
  font-size: 14px;
  text-align: center;
}

.responsiveRightContainer {
  justify-content: flex-end;
}

@media screen and (max-width: 600px) {
  .responsiveRightIconLabel {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .responsiveRightContainer {
    justify-content: flex-start;
    margin-bottom: 10px;
    min-width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .centerJustifyMobile {
    justify-content: center !important;
  }
}

@media print {
  .background {
    background-color: white;
  }

  .bisectedLayoutLowerContainer {
    margin-top: 0px;
  }
}

.appointmentTypeCard {
  border: none;
  border-radius: 10px;
  margin-bottom: 15px;
  border: 2px solid white;
  box-shadow: 0 10px 25px rgba(50, 50, 93, 0.1);
}

.appointmentTypeCardSelectable {
  composes: appointmentTypeCard;
}

.appointmentTypeCardSelectable:hover {
  box-shadow: 0 0px 25px rgba(38, 94, 214, 0.15);
  border: 2px solid rgb(38, 94, 214);
  cursor: pointer;
}

.appointmentTypeCardSelected {
  composes: appointmentTypeCard;
  background-color: #265ed6;
  border: 2px solid rgb(38, 94, 214);
  box-shadow: 0 0px 25px rgba(38, 94, 214, 0.15);
  color: white;
}

.appointmentTypeCardSelected:hover {
  background-color: #265ed6;
  border: 2px solid rgb(38, 94, 214);
  box-shadow: 0 0px 25px rgba(38, 94, 214, 0.15);
  color: white;
}

.dateBar {
  text-align: center;
  background-color: rgb(247, 247, 247);
}

.availabilityCol {
  padding: 0px;
}

:global .btn-wizard-nav-cancel {
  display: none;
}

:global .btn-wizard-nav-submit {
  background-color: #265ed6;
  border-radius: 10px;
  margin-left: -12px;
  font-size: 1.25rem;
  line-height: 1.5;
  padding: 0.5rem 1rem;
  margin-top: 15px;
}

:global .btn-wizard-nav-next {
  background-color: #265ed6;
  border-radius: 10px;
  margin-left: -12px;
  font-size: 1.25rem;
  line-height: 1.5;
  padding: 0.5rem 1rem;
  margin-top: 15px;
}

:global .btn-wizard-nav-previous {
  background-color: #265ed6;
  border-radius: 10px;
  margin-left: -12px;
  margin-right: 12px;
  font-size: 1.25rem;
  line-height: 1.5;
  padding: 0.5rem 1rem;
  margin-top: 15px;
}

:global .alert.alert-success {
  display: none;
}

:global .modal-content {
  border-radius: 10px;
  border: none;
}

:global .modal-footer {
  border: none;
}

.upperpanel {
  background-color: white;
  box-shadow: 0px 0px 19px rgba(102, 106, 126, 0.15);
  padding-bottom: 20px;
}

.bisectedLayoutUpperPanel {
  background-color: white;
  box-shadow: 0px 0px 19px rgba(102, 106, 126, 0.15);
  padding-bottom: 20px;
}

.bisectedLayoutUpperPanelYkCallCentre {
  background-color: white;
  box-shadow: 0px 20px 19px -20px rgba(102, 106, 126, 0.15);
  padding-bottom: 20px;
}

.actionButtonsContainer {
  margin-top: 10px;
}

@media (min-width: 992px) {
  .actionButtonsContainer {
    margin-top: 0px;
    justify-content: flex-end;
  }

  .actionButtonsRow {
    flex-wrap: nowrap;
  }
}

.actionButtonsContainerAppointmentType {
  margin-top: 10px;
  margin-top: 0px;
  justify-content: flex-end;
}

.prepStepOuterRow {
}

.prepStepRightContainer {
  margin-top: 5px;
  padding-left: 0px;
}

@media (min-width: 576px) {
  .prepStepOuterRow {
    flex-wrap: nowrap;
  }

  .prepStepRightContainer {
    margin-top: 0px;
    justify-content: flex-end;
    padding-left: 15px;
  }
}
