.doseList {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 60px;
  box-shadow: 1px 2px 8px rgba(88, 88, 88, 0.2);
  flex-direction: column;
  padding: 15px;
  background: white;
  display: flex;
}

.largeTitle {
  width: 100%;
  height: 28px;
  font-size: 32px;
  line-height: 25px;
  font-weight: bold;
  margin: 0px;
}

.title {
  width: 100%;
  height: 25px;
  font-size: 26px;
  line-height: 25px;
  font-weight: bold;
  /* padding: 5px 10px; */
  margin-top: 50px;
}

.sectionTitle {
  width: 100%;
  height: 25px;
  font-size: 26px;
  line-height: 25px;
  font-weight: bold;
  margin-top: 20px;
}

.pvcContainer {
  border-radius: 10px;
  border-style: solid;
  padding: 10px;
  box-shadow: 1px 2px 8px rgba(88, 88, 88, 0.2);
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: white;
}

.pvcRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: top;
}

.pvcText {
  width: 90%;
  font-size: 14px;
  margin-top: 10px;
  margin-left: 20px;
}

.pvcButton {
  background-color: rgb(0, 100, 164);
  color: white;
  border: none;
  padding: 40px;
}

.pvcImage {
  width: 30%;
  height: auto;
  margin-right: 1%;
}

.pvcBody {
  width: 70%;
}

.subtitle {
  margin-bottom: 0px;
  font-size: 22px;
}

.description {
  font-weight: bold;
  margin: 0px 0px 25px 0px;
}

.actionText {
  width: 100%;
}

.content {
  width: 66vw;
  max-width: 900px;
  min-width: 775px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 0px 0px 10px 0px;
  min-height: 100px;
  max-height: none;
  flex-flow: column wrap;
  margin-top: 20px;
}

.contentAdminConsole {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 0px 0px 10px 0px;
  min-height: 100px;
  max-height: none;
  flex-flow: column wrap;
  min-width: 375px;
}

.option {
  display: flex;
  font-size: 15px;
  flex-direction: row;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  align-items: center;
}

.option > p {
  margin: 0;
  padding: 15px;
}

.icon {
  width: 50px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon > p {
  color: darkred;
  margin: 0px 15px 0px 0px;
}

.chevron {
  font-size: 20px;
  color: rgba(88, 88, 88, 0.5);
  transform: translateY(-100%);
}

.chevron::before {
  left: 0;
  top: 0.15em;
  width: 0.45em;
  height: 0.45em;
  margin-left: -5px;
  margin-bottom: -5px;
  position: relative;
  display: inline-block;
  color: lightgray;
  vertical-align: top;
  border-style: solid;
  border-width: 0.15em 0.15em 0 0;
  transform: rotate(45deg);
  content: '';
}

.contentRow {
  width: 95%;
  display: flex;
  flex-direction: row;
  margin-top: 7.5px;
  margin-bottom: 7.5px;
  margin-left: 15px;
}

.upcomingAppointmentButton {
  margin-top: 15;
  margin-bottom: 15;
  background-color: rgb(0, 100, 164);
  color: white;
  border: none;
}

.upcomingAppointmentButton:hover {
  background-color: rgba(12, 117, 182, 0.87) !important;
  color: white;
}

@media print {
  .noPrint * {
    display: none !important;
  }

  .contentAdminConsole {
    max-height: 500px !important;
  }

  .content {
    max-height: 500px !important;
  }
}

@media (max-width: 2080px) {
  .contentAdminConsole {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 0px 0px 10px 0px;
    min-height: 100px;
    max-height: 800px;
    flex-flow: column wrap;
  }
}

@media (max-width: 1200px) {
  .pvcImage {
    display: none;
  }
  .pvcBody {
    width: 100%;
  }
}

@media (orientation: portrait) {
  .content {
    min-width: 375px;
    max-height: 800px;
    padding: 0px 0px 0px 0px !important;
  }
  .contentAdminConsole {
    min-width: 375px;
    max-height: 800px;
    padding: 0px 0px 0px 0px !important;
  }
}

@page {
  margin: 1in;
}
