@import '~react-image-gallery/styles/css/image-gallery.css';

body {
  margin: 0;
  font-size: 18px !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hide-support-widget iframe {
  display: none;
}

.pointer {
  cursor: pointer;
}

.markdown-field a {
  text-decoration: underline;
}
.markdown-field a:hover {
  background-color: #f8d44c;
  text-decoration: underline;
}

.booking-form-provider p a {
  text-decoration: underline;
}

.booking-form-provider p a:hover {
  background-color: #f8d44c;
  text-decoration: underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-card {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 9px 2px;
}

.language-picker .btn-secondary {
  background-color: white;
  color: #9f0c35;
  border-color: #9f0c35;
}

.language-picker .btn-secondary:not(:disabled):not(.disabled).active {
  background-color: #9f0c35;
  border-color: #9f0c35;
}

.language-picker .btn-secondary:not(:disabled):not(.disabled).active:focus {
  outline: none;
  box-shadow: none;
}

.col-form-label {
  font-weight: bold;
}

.ant-page-header {
  padding: 16px 0px !important;
}

.anticon {
  vertical-align: 0.1em !important;
}

.ant-table {
  overflow: hidden;
}

.message-yellow {
  background-color: #fff3cd;
  border-color: #ffecb5;
  color: #664d03;
  padding: 15px;
  margin-top: -30px;
  margin-bottom: 10px;
  border-radius: 10px;
  margin-bottom: 40px;
}

.message-red {
  background-color: #f8d7da;
  border-color: #f5c2c7;
  color: #842029;
  padding: 15px;
  margin-top: -30px;
  margin-bottom: 10px;
  border-radius: 10px;
  margin-bottom: 40px;
}

/* Fixes bug in formio */
.choices__list--dropdown {
  display: none;
}

.choices__list--dropdown.is-active {
  display: block;
}

.icon-card-col {
  display: flex;
}

.icon-card {
  background-color: #eff1fa;
  border-radius: 10px;
  padding: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
}

.icon-card .title {
  font-weight: bold;
  margin-bottom: 20px;
}

.icon-card img {
  width: 30px;
  margin-bottom: 20px;
}

/* .form-group .formio-component.formio-component-radio {
  margin-bottom: 40px;
}
.form-group .formio-component.formio-component-selectboxes {
  margin-bottom: 40px;
} */

.form-group.formio-component.formio-component.formio-component-fieldset {
  margin-bottom: 0px;
}

.form-group.formio-component.formio-component {
  margin-bottom: 40px;
}

.form-group.formio-component.formio-component.formio-hidden {
  margin-bottom: 0px;
}

.form-group.formio-component.formio-component.specify-field {
  margin-top: -40px;
  /* margin-bottom: 0px; */
}

.form-group.formio-component.formio-component.specify-field.formio-hidden {
  margin-top: 0px;
}

.markdown-field ul {
  margin-bottom: 1em;
}

.availability-rule-collapse-panel > .ant-collapse-header {
  display: flex;
}

.availability-rule-collapse-panel .ant-collapse-extra {
  display: flex;
  align-items: center;
}

div .receipt-datepicker:focus {
  outline: none;
  border-color: var(--primary-hover-color);
  box-shadow: 0 0 0 2px var(--primary-shadow-color);
}
.receipt-datepicker {
  width: 300px;
  font-size: 16px !important;
}

.yukon-pvc-datepicker {
  width: 100% !important;
  display: block !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.ant-checkbox-wrapper.emphasized-checkbox:not(:hover)
  .ant-checkbox:not(.ant-checkbox-checked)
  .ant-checkbox-inner {
  border: 1px solid rgba(170, 170, 170, 1);
  /* opacity: .5; */
  filter: grayscale(10%);
}

.ant-checkbox-wrapper.square .ant-checkbox-inner {
  border-radius: 2px;
}

.button-container button {
  margin-bottom: 10px;
}

.status-cell {
  margin-bottom: 2px !important;
}

@media print {
  .hide-on-print {
    display: none;
  }
}

@media print {
  .invisible-on-print {
    visibility: hidden;
  }
}

.show-on-print {
  display: none;
}

@media print {
  .show-on-print {
    display: inherit;
  }
}

/* Full Calendar */
.fc-event-block {
  color: rgb(255, 255, 255);
  --fc-bg-event-opacity: 0.5;
}

.ant-collapse > .ant-collapse-item.smallPanel > .ant-collapse-header {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 24px;
}

.ant-collapse > .ant-collapse-item.smallPanel > .ant-collapse-header .anticon {
  padding: 4px;
  left: 4px;
}