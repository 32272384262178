.appMenu {
  height: 100%;
}

.appContent {
  margin: 24px 80px;
}

.appSider {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.appLogo {
  height: 62px;
  line-height: 62px;
  text-align: center;
}

.publicWrapper {
  width: 960px;
  max-width: 100%;
  padding: 16px;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.headerBackground {
  /* left: 0; */
  /* right: 0; */
  overflow: visible;
  margin-left: -100px;
  margin-right: -100px;
  height: 0px;
  margin-top: -58px;
  margin-bottom: 88px;
}

.headerBackgroundInner {
  /* width: 100%; */
  height: 100vh;
}
