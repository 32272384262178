.navWrapper {
  padding: 0px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 10;
}

.navMenu {
  padding: 10px 20px;
  flex-shrink: 0;
}

.navMenu > a {
  color: white;
  font-weight: bold;
}

.navMenu :global .ant-btn {
  border-radius: 40px;
  background-color: rgba(0, 0, 0, 0.2);
  border: none;
  color: white;
  font-weight: bold;
}

.navMenuGroup {
  display: flex;
  align-items: center;
}

.navAvatar {
  line-height: 30px;
  margin: 0px 5px;
}

.navTitle {
  padding: 10px 20px;
  flex-grow: 0;
}

.dashboardOrgSelector {
  width: 200px;
}

.customSelect .dashboardOrgSelector :global .ant-select-selector {
  border-radius: 40px;
  background-color: rgba(0, 0, 0, 0.2);
  border: none;
  color: white;
}

.customSelect .dashboardOrgSelector :global .ant-select-selector .ant-select-selection-item {
  font-weight: bold !important;
}

.navMenu :global .ant-btn:hover {
  background-color: rgba(0, 0, 0, 0.35);
}

.centerMenu {
  flex-grow: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fhirSearchInput {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 40px;
  border: none;
  color: white;
  height: 32px;
  width: 200px;
}

.fhirSearchInputk ::placeholder {
  color: white;
}

.fhirSearchInputFocussed {
  background-color: white;
  border-radius: 40px;
  border: none;
  height: 32px;
  z-index: 100;
  width: 400px;
  transition-property: all;
  transition-duration: 400ms;
}

.fhirSearchInput:hover {
  background-color: rgba(0, 0, 0, 0.35);
}

.fhirSearchInput :global .ant-input {
  background-color: transparent;
  border-radius: 0px;
}

.fhirSearchInput :global .ant-input::placeholder {
  color: white;
  font-weight: bold;
}

.fhirSearchInputFocussed :global .ant-input {
  background-color: transparent;
  border-radius: 0px;
}
