.doseBox {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border-left: none;
  /* height: 100%; */
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
}

.doseBoxContainer {
  position: relative;
  border-radius: 10px;
  box-shadow: 1px 2px 8px rgba(88, 88, 88, 0.2);
  margin-bottom: 10px;
  margin-right: 30px;
}

.doseBoxContainerNS {
  position: relative;
  box-shadow: 1px 2px 8px rgba(88, 88, 88, 0.1);
}

.doseBoxNS {
  background-color: #fff;
  border-left: none;
  /* height: 100%; */
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
}

.borderGreen {
  border-left-color: #65c76b;
}

.borderDisabled {
  border-left-color: #b2e3b5;
}

.disabled {
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
}

.content {
  min-width: 3.8in;
  display: flex;
  flex-flow: column;
  min-width: 0;
}

.title {
  color: #000;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text {
  color: #000;
  font-size: 16px;
  line-height: 20px;
  overflow: hidden;
  overflow-wrap: break-word;
}

.icon {
  width: 50px;
  margin-left: 10px;
  margin-right: 10px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media print {
  .text {
    max-width: 4in !important;
  }
}