.tooltip :global .ant-popover-content .ant-popover-arrow-content {
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.tooltip :global .ant-popover-content .ant-popover-inner {
  max-width: 250px !important;
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.tooltip :global .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
  color: white;
  padding: 6px 8px;
}
