.fhirTableSearchBar :global .ant-input-affix-wrapper {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  flex: 1;
  flex-shrink: 1;
}

.fhirTableSearchBar {
  border-right: none;
}
