.descriptionText {
  font-size: 20pt;
}
.headerLogo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  width: 20%;
}

.businessCardsContainer {
  display: flex;
  margin-top: 30px;
}

.businessCardContainer {
  position: relative;
}

.businessCard {
  width: 5.25in;
  height: 3.2in;
  box-shadow: 1px 2px 8px rgba(88, 88, 88, 0.2);
  flex-direction: column;
  background: rgb(255, 255, 255);
  display: flex;
  border-color: rgba(83, 83, 83, 0.363);
  border-style: dashed;
  border-width: 2px;
}

.businessCardRear {
  width: 5.25in;
  height: 3.2in;
  box-shadow: 1px 2px 8px rgba(88, 88, 88, 0.2);
  flex-direction: row;
  background: rgb(255, 255, 255);
  display: flex;
  border-color: rgba(83, 83, 83, 0.363);
  border-style: dashed;
  border-width: 2px;
}

.businessCardHeader {
  width: 100%;
  margin-top: 3px;
  margin-bottom: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.businessCardDivider {
  display: block;
  height: 2px;
  border: 0;
  border-top: 3px solid rgb(0, 84, 140);
  margin: 0;
}

.descriptionDivider {
  display: block;
  height: 2px;
  border: 0;
  border-top: 3px solid rgba(0, 0, 0, 0.42);
  margin: 0;
}

.nsLogo {
  height: 40px;
  margin-left: 25px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.nsLogoRear {
  height: 40px;
  margin-top: 3px;
  margin-bottom: 9px;
  margin-left: 25px;
}

.canImmunizeLogo {
  height: 12px;
  margin-right: 20px;
  margin-top: 15px;
}

.titleCard {
  font-size: 24px;
  font-weight: bold;
}

.subtitleCard {
  font-size: 15px;
}

.patientLabelContainer {
  margin-left: 30px;
  margin-right: 30px;
}

.cardLabelContainer {
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  margin-right: 30px;
}

.contentRow {
  width: 95%;
  display: flex;
  flex-direction: row;
  margin-top: 7.5px;
  margin-bottom: 7.5px;
  margin-left: 15px;
}

.doseBoxGrid {
  width: 97%;
  display: flex;
  flex-direction: row;
  margin-top: 7.5px;
  margin-bottom: 7.5px;
  margin-left: 15px;
  flex-wrap: wrap;
}

.qrCode {
  max-width: 100%;
  margin: 15px;
}

.qrContainer {
  width: 80%;
  height: 80%;
  align-items: center;
}

.rearContentContainer {
  width: 50%;
  margin-left: 0px;
  margin-top: 0px;
}

.indented {
  margin-left: 20px;
}
